import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Bell, Menu, Home, BarChart2, TrendingUp, BookOpen } from 'lucide-react';

const chartData = [
  { name: '00:00', value: 42000 },
  { name: '04:00', value: 43000 },
  { name: '08:00', value: 42800 },
  { name: '12:00', value: 43200 },
  { name: '16:00', value: 43800 },
  { name: '20:00', value: 44000 },
];

const MobileTradingPlatform = () => {
  return (
    <div className="min-h-screen bg-[#0B1120]">
      {/* Header */}
      <div className="flex items-center justify-between p-4">
        <Menu className="h-6 w-6 text-blue-400" />
        <span className="text-xl font-medium text-blue-400">MFT Assistant</span>
        <Bell className="h-6 w-6" />
      </div>

      {/* Main Content */}
      <div className="px-4 pb-20">
        {/* Trading Header */}
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-2xl font-bold text-white">BTC/USDT</h1>
          <div className="flex gap-2">
            <button className="px-3 py-1 bg-green-500 text-white rounded text-sm">Al</button>
            <button className="px-3 py-1 bg-red-500 text-white rounded text-sm">Sat</button>
          </div>
        </div>
        <div className="mb-4">
          <span className="text-3xl font-bold text-white">$44,250.00 </span>
          <span className="text-green-500">+5.2%</span>
        </div>

        {/* Chart */}
        <div className="bg-[#1E293B] rounded-xl p-4 mb-6">
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#334155" />
              <XAxis dataKey="name" stroke="#64748B" />
              <YAxis stroke="#64748B" />
              <Tooltip 
                contentStyle={{
                  backgroundColor: '#1E293B',
                  border: 'none',
                  borderRadius: '8px',
                }}
                labelStyle={{ color: '#64748B' }}
                itemStyle={{ color: '#fff' }}
              />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#3B82F6" 
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 6, fill: '#3B82F6' }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* AI Assistant */}
        <div className="mb-6">
          <div className="flex items-center gap-2 mb-4">
            <span className="text-lg font-medium text-white">AI Asistan Önerisi</span>
          </div>
          <div className="space-y-2">
            <div className="bg-[#1E293B] p-4 rounded-xl">
              <div className="text-gray-400">Market Analizi</div>
              <div className="text-white mt-1">Bitcoin'de güçlü alım sinyali. RSI ve MACD göstergeleri pozitif.</div>
            </div>
            <div className="bg-[#1E293B] p-4 rounded-xl">
              <div className="text-gray-400">Önerilen İşlem</div>
              <div className="text-white mt-1">Stop-loss: $42,500 | Hedef: $46,000</div>
            </div>
          </div>
        </div>

        {/* AI Trading Signals */}
        <div>
          <h2 className="text-lg font-medium text-white mb-4">AI Trading Sinyalleri</h2>
          <div className="space-y-2">
            <div className="bg-[#1E293B] p-4 rounded-xl">
              <div className="flex justify-between">
                <div>
                  <div className="text-white font-medium">BTC/USDT</div>
                  <div className="text-gray-400">Güçlü Alım</div>
                  <div className="text-gray-400 mt-2">Güven: 92%</div>
                  <div className="text-gray-400">2 dk önce</div>
                </div>
                <div className="text-right">
                  <div className="text-white font-medium">$44,250</div>
                  <div className="text-green-500">+5.2%</div>
                </div>
              </div>
            </div>
            <div className="bg-[#1E293B] p-4 rounded-xl">
              <div className="flex justify-between">
                <div>
                  <div className="text-white font-medium">ETH/USDT</div>
                  <div className="text-gray-400">Nötr</div>
                  <div className="text-gray-400 mt-2">Güven: 85%</div>
                  <div className="text-gray-400">5 dk önce</div>
                </div>
                <div className="text-right">
                  <div className="text-white font-medium">$2,850</div>
                  <div className="text-green-500">+1.8%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-[#1E293B] border-t border-gray-800">
        <div className="grid grid-cols-4">
          <button className="flex flex-col items-center py-3 text-blue-400">
            <Home className="h-6 w-6" />
            <span className="text-xs mt-1">Ana Sayfa</span>
          </button>
          <button className="flex flex-col items-center py-3 text-gray-400">
            <BarChart2 className="h-6 w-6" />
            <span className="text-xs mt-1">Piyasalar</span>
          </button>
          <button className="flex flex-col items-center py-3 text-gray-400">
            <TrendingUp className="h-6 w-6" />
            <span className="text-xs mt-1">Trade</span>
          </button>
          <button className="flex flex-col items-center py-3 text-gray-400">
            <BookOpen className="h-6 w-6" />
            <span className="text-xs mt-1">Portföy</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileTradingPlatform; 