import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Container, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/CrptoCSS.css';

const symbolMapping = {
  'BTCUSDT': 'Bitcoin',
  'ETHUSDT': 'Ethereum',
  'BNBUSDT': 'BNB',
  'SOLUSDT': 'Solana',
  'USDTUSDT': 'Tether'
};

const BinanceMarketCap = () => {
  const [coins, setCoins] = useState([]);
  const ws = useRef(null);
  const timerRef = useRef(null);

  const updateCoinData = useCallback((data) => {
    setCoins((prevCoins) => {
      const updatedCoins = [...prevCoins];
      const index = updatedCoins.findIndex(coin => coin.symbol === data.s);
      const price = parseFloat(data.c);
      const volume = parseFloat(data.q);
      const marketCap = price * parseFloat(data.v);
      const change = parseFloat(data.P);
      const changeFormatted = change > 0 ? `+${change.toFixed(2)}` : `${change.toFixed(2)}`;

      if (index !== -1) {
        updatedCoins[index] = {
          ...updatedCoins[index],
          price: price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          volume: volume.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          marketCap: marketCap.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          change: changeFormatted,
        };
      } else {
        updatedCoins.push({
          symbol: data.s,
          name: symbolMapping[data.s] || data.s,
          price: price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          volume: volume.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          marketCap: marketCap.toLocaleString('en-US', { maximumFractionDigits: 2 }),
          change: changeFormatted,
        });
      }
      return updatedCoins.sort((a, b) => parseFloat(b.price.replace(/[^0-9.-]+/g,"")) - parseFloat(a.price.replace(/[^0-9.-]+/g,"")));
    });
  }, []);

  const setupWebSocket = useCallback(() => {
    if (ws.current) {
      ws.current.close();
    }

    ws.current = new WebSocket('wss://stream.binance.com:9443/ws');

    ws.current.onopen = () => {
      if (ws.current) {
        ws.current.send(JSON.stringify({
          method: 'SUBSCRIBE',
          params: ['btcusdt@ticker', 'ethusdt@ticker', 'bnbusdt@ticker', 'solusdt@ticker', 'usdtusdt@ticker'],
          id: 1
        }));
      }
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.e === '24hrTicker') {
        updateCoinData(data);
      }
    };
  }, [updateCoinData]);

  useEffect(() => {
    setupWebSocket();

    timerRef.current = setInterval(() => {
      setupWebSocket();
    }, 10000); // Refresh WebSocket every 10 seconds

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [setupWebSocket]);

  const memoizedCoins = useMemo(() => coins, [coins]);

  return (
    <Container className="crypto-container">
      <h1 className="crypto-title">Crypto Market Cap</h1>
      <div className="table-responsive">
        <Table hover className="crypto-table">
          <thead>
            <tr>
              <th style={{ width: '5%' }} className="index-column">#</th>
              <th style={{ width: '20%' }}>Name</th>
              <th style={{ width: '20%' }} className="text-end">Price</th>
              <th style={{ width: '15%' }} className="text-end">24h Change</th>
              <th style={{ width: '20%' }} className="market-cap-column text-end">Market Cap</th>
              <th style={{ width: '20%' }} className="volume-column text-end">Volume(24h)</th>
            </tr>
          </thead>
          <tbody>
            {memoizedCoins.map((coin, index) => (
              <tr key={coin.symbol}>
                <td className="index-column">{index + 1}</td>
                <td>{coin.name} <span className="symbol">{coin.symbol.replace('USDT', '')}</span></td>
                <td className="text-end">{coin.price}</td>
                <td className="text-end" style={{ color: coin.change.startsWith('+') ? 'green' : 'red' }}>{coin.change}%</td>
                <td className="market-cap-column text-end">${coin.marketCap}</td>
                <td className="volume-column text-end">${coin.volume}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="refresh-timer">Refresh: 10 second</div>
    </Container>
  );
};

export default BinanceMarketCap;