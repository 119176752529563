import React, { useState, useEffect, useRef } from 'react';
import { OpenAI } from 'openai';
import { RiSendPlaneFill, RiCloseLine, RiFullscreenLine, RiFullscreenExitLine, RiRobot2Line, RiShoppingCartLine } from 'react-icons/ri';
import { CHATBOT_PROMPT } from './chatbot-prompt';
import './ChatComponent.css';
import { Modal } from 'react-bootstrap';
import BuyTokenWizard from '../About/BuyTokenView';

const ChatComponent = ({ isOpen, toggleChat }) => {
    const apiKey = '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';
    const openai = new OpenAI({ apiKey, dangerouslyAllowBrowser: true });

    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [currentTypingText, setCurrentTypingText] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [buyAmount, setBuyAmount] = useState(0);
    const [cryptoPrices, setCryptoPrices] = useState({
        bnb: 0,
        eth: 0,
        mftPriceInBnb: 0,
        mftPriceInEth: 0
    });
    const messagesEndRef = useRef(null);
    const typingTimeoutRef = useRef(null);

    // Kripto fiyatlarını al
    const fetchCryptoPrices = async () => {
        try {
            const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin%2Cethereum&vs_currencies=usd');
            const data = await response.json();
            
            const bnbPrice = data.binancecoin.usd;
            const ethPrice = data.ethereum.usd;
            const mftPrice = 0.005000; // Sabit MFT fiyatı

            // MFT fiyatlarını BNB ve ETH cinsinden hesapla
            const mftPriceInBnb = mftPrice / bnbPrice;
            const mftPriceInEth = mftPrice / ethPrice;

            setCryptoPrices({
                bnb: bnbPrice,
                eth: ethPrice,
                mftPriceInBnb,
                mftPriceInEth
            });
        } catch (error) {
            console.error('Kripto fiyatları alınamadı:', error);
        }
    };

    // Başlangıçta ve her 5 dakikada bir fiyatları güncelle
    useEffect(() => {
        fetchCryptoPrices();
        const interval = setInterval(fetchCryptoPrices, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setMessages([{ 
            text: "Hallo! Ich bin der MyFutureToken AI Assistent. Wie kann ich dir helfen?", 
            sender: 'bot',
            isTyping: false 
        }]);
    }, []);

    useEffect(() => {
        if (isOpen) {
            scrollToBottom();
        }
    }, [messages, isOpen]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const typeMessage = async (text) => {
        setIsTyping(true);
        let currentText = '';
        const words = text.split(' ');
        
        for (let i = 0; i < words.length; i++) {
            currentText += (i === 0 ? '' : ' ') + words[i];
            setCurrentTypingText(currentText);
            await new Promise(resolve => setTimeout(resolve, 50)); // Kelime yazma hızı
        }
        
        setIsTyping(false);
        return text;
    };

    const extractJsonFromMessage = (message) => {
        try {
            // Önce ```json ve ``` işaretlerini temizle
            const jsonMatch = message.match(/```json\s*({.*?})\s*```/s);
            if (jsonMatch && jsonMatch[1]) {
                // JSON stringi temizle ve parse et
                const cleanJson = jsonMatch[1].replace(/\\n/g, '').trim();
                return JSON.parse(cleanJson);
            }
            // Eğer ```json formatında değilse, direkt JSON string'i ara
            const directJsonMatch = message.match(/{[\s\S]*"action"[\s\S]*"amount"[\s\S]*}/);
            if (directJsonMatch) {
                return JSON.parse(directJsonMatch[0]);
            }
            return null;
        } catch (error) {
            console.error('JSON parsing error:', error);
            return null;
        }
    };

    const handleBuyButtonClick = () => {
        setShowBuyModal(true);
    };

    const sendMessage = async () => {
        if (!input.trim()) return;

        const userMessage = { text: input, sender: 'user', isTyping: false };
        setMessages(prev => [...prev, userMessage]);
        setInput('');
        setIsLoading(true);

        try {
            const botResponse = await getBotResponse(input);
            setIsLoading(false);
            
            // JSON yanıtı kontrol et
            const jsonData = extractJsonFromMessage(botResponse);
            
            // JSON kısmını mesajdan temizle
            const cleanResponse = botResponse
                .replace(/```json[\s\S]*?```/g, '')  // ```json``` bloğunu temizle
                .replace(/{[\s\S]*"action"[\s\S]*"amount"[\s\S]*}/, '')  // Direkt JSON'ı temizle
                .trim();
            
            // Bot mesajını ekle ama henüz boş olarak
            setMessages(prev => [...prev, { 
                text: '', 
                sender: 'bot', 
                isTyping: true,
                action: jsonData?.action,
                amount: jsonData?.amount
            }]);
            
            // Mesajı kelime kelime yaz
            await typeMessage(cleanResponse);
            
            // Son mesajı güncelle
            setMessages(prev => [
                ...prev.slice(0, -1),
                { 
                    text: cleanResponse, 
                    sender: 'bot', 
                    isTyping: false,
                    action: jsonData?.action,
                    amount: jsonData?.amount
                }
            ]);

            if (jsonData?.action === 'buy_token') {
                setBuyAmount(jsonData.amount);
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            setMessages(prev => [...prev, {
                text: 'Entschuldigung, es gab einen Fehler. Bitte versuchen Sie es später erneut.',
                sender: 'bot',
                isTyping: false
            }]);
        }
    };

    const getBotResponse = async (inputText) => {
        try {
            // Dinamik prompt oluştur
            const dynamicPrompt = CHATBOT_PROMPT + `\n\nAKTUELLE PREISE:
- 1 BNB = $${cryptoPrices.bnb.toFixed(2)}
- 1 ETH = $${cryptoPrices.eth.toFixed(2)}
- 1 MFT = ${cryptoPrices.mftPriceInBnb.toFixed(8)} BNB
- 1 MFT = ${cryptoPrices.mftPriceInEth.toFixed(8)} ETH`;

            const completion = await openai.chat.completions.create({
                model: "gpt-4",
                messages: [{
                    role: "system",
                    content: dynamicPrompt
                }, {
                    role: "user",
                    content: inputText
                }]
            });

            return completion.choices[0].message.content;
        } catch (error) {
            console.error('OpenAI Error:', error);
            throw error;
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    const chatContainerStyle = isOpen ? {
        display: 'flex',
        flexDirection: 'column',
        width: isExpanded ? '50%' : '350px',
        height: isExpanded ? '70vh' : '500px',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
    } : {
        display: 'none',
    };

    return (
        <>
            <div className={`chat-container ${isOpen ? 'open' : ''}`} style={chatContainerStyle}>
                <div className="chat-header">
                    <div className="chat-header-title">
                        <RiRobot2Line size={24} />
                        <span>MyFutureToken AI Assistant</span>
                    </div>
                    <div className="chat-controls">
                        <button onClick={toggleExpand} className="chat-control-button">
                            {isExpanded ? <RiFullscreenExitLine size={20} /> : <RiFullscreenLine size={20} />}
                        </button>
                        <button onClick={toggleChat} className="chat-control-button">
                            <RiCloseLine size={20} />
                        </button>
                    </div>
                </div>
                
                <div className="chat-body">
                    {messages.map((message, index) => (
                        <div key={index} className={`message ${message.sender}`}>
                            {message.text}
                            {message.action === 'buy_token' && (
                                <button 
                                    className="buy-token-button"
                                    onClick={handleBuyButtonClick}
                                >
                                    <RiShoppingCartLine /> Token Kaufen
                                </button>
                            )}
                        </div>
                    ))}
                    {isTyping && (
                        <div className="message bot">
                            {currentTypingText}
                        </div>
                    )}
                    {isLoading && (
                        <div className="typing-indicator">
                            <div className="typing-dot"></div>
                            <div className="typing-dot"></div>
                            <div className="typing-dot"></div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                
                <div className="chat-footer">
                    <div className="chat-input-container">
                        <input
                            type="text"
                            className="chat-input"
                            placeholder="Schreiben Sie Ihre Nachricht..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={handleKeyPress}
                            disabled={isLoading}
                        />
                    </div>
                    <button 
                        className="send-button"
                        onClick={sendMessage}
                        disabled={isLoading || !input.trim()}
                    >
                        <RiSendPlaneFill size={20} />
                    </button>
                </div>
            </div>

            <Modal
                show={showBuyModal}
                onHide={() => setShowBuyModal(false)}
                dialogClassName="buy-token-modal"
                backdropClassName="buy-token-overlay"
                size="lg"
                centered
                animation={false}
            >
                <Modal.Body>
                    <BuyTokenWizard 
                        onClose={() => setShowBuyModal(false)}
                        initialAmount={buyAmount}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ChatComponent;
