import React, { useEffect, useState } from "react";
import aboutImg from "../../assets/img/banner/MFC-NetworkMarketing.png";
import Parse from 'parse';
import NetworkMarketingModal from "../NetworkMarketingModal";
import styled from 'styled-components';

const OrangeButton = styled.a`
  display: inline-block;
  background-color: #F7941D; // Turuncu renk
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #E5810F; // Hover durumunda daha koyu bir turuncu
    color: white;
    text-decoration: none;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;

const OriginalCaseText = styled.h2`
  text-transform: none !important;
`;



const NetworkMarketingButton = ({ onClick }) => {
  return (
    <OrangeButton href="#register" onClick={onClick}>
      Bewerben Sie sich für Network Marketing
    </OrangeButton>
  );
};

const NetworkMarketing = () => {
  const [aboutTitle, setAboutTitle] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Parse sorgusu ile myfacecoin objesinden aboutTitle ve aboutDescription çekme
    const MyFaceCoin = Parse.Object.extend("myfacecoin");
    const query = new Parse.Query(MyFaceCoin);
    query.first().then((object) => {
      if (object) {
        setAboutTitle(object.get("aboutTitle"));
        setAboutDescription(object.get("aboutDescription"));
      } else {
        console.log("MyFaceCoin objesi bulunamadı.");
      }
    }).catch((error) => {
      console.error("Parse sorgusunda hata:", error);
    });
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <section id="about" className="about-area-two">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="text-center">
              <img src={aboutImg} alt="About Image" style={{ marginBottom: '20px', width: '800px' }} />
            </div>
          </div>
          <div className="col-lg-8">
            <div className="about-content-two text-center wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title section-title-two mb-15">
              <OriginalCaseText className="title" style={{ textAlign: 'center' }}>
                  Werden Sie Teil des MyFutureToken (MFT) Netzwerks und verdienen Sie passives Einkommen!
                </OriginalCaseText>
              </div>
              <p className="about-description">
                Starten Sie Ihre Reise in die Zukunft des dezentralen Finanzwesens mit MyFutureToken! Unser innovatives 5-Stufen-Netzwerk-Marketing-System bietet Ihnen die Möglichkeit, durch aktive Teilnahme und Teamaufbau zu profitieren:

                • Stufe 1: Direkte Empfehlungen - Verdienen Sie 5% Provision
                • Stufe 2: Team-Expansion - Erhalten Sie 3% von Ihrem erweiterten Netzwerk
                • Stufe 3: Netzwerk-Wachstum - Sichern Sie sich 2% zusätzlichen Bonus
                • Stufe 4: Community-Building - Profitieren Sie von 1,5% der Teamaktivitäten
                • Stufe 5: Leadership-Level - Genießen Sie 1% passive Einnahmen

                Zusätzlich bieten wir exklusive Leadership-Boni und monatliche Pool-Rewards für Top-Performer. Werden Sie jetzt Teil unserer wachsenden Community!
              </p>
              <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <NetworkMarketingButton onClick={handleButtonClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NetworkMarketingModal show={showModal} onHide={() => setShowModal(false)} />
    </section>
  );
};

export default NetworkMarketing;