import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MFTStory = () => {
  const data = [
    { date: 'Feb 2025', price: 0.005000, sold: 16000000 },
    { date: 'Mär 2025', price: 0.005300, sold: 47169811.32 },
    { date: 'Apr 2025', price: 0.006100, sold: 129508196.72 },
    { date: 'Mai 2025', price: 0.007900, sold: 227848101.27 },
    { date: 'Jun 2025', price: 0.012600, sold: 373015873.02 },
    { date: 'Jul 2025', price: 0.025100, sold: 498007968.13 },
    { date: 'Aug 2025', price: 0.046800, sold: 463675213.68 },
    { date: 'Sep 2025', price: 0.085700, sold: 453908984.83 },
    { date: 'Okt 2025', price: 0.142200, sold: 397327707.45 },
    { date: 'Nov 2025', price: 0.213400, sold: 333645735.71 },
    { date: 'Dez 2025', price: 0.350800, sold: 391676168.76 },
    { date: 'Jan 2026', price: 0.561000, sold: 374688057.04 },
    { date: 'Feb 2026', price: 0.955000, sold: 412565445.03 },
  ];

  const formatCurrency = (value) => {
    if (value >= 1000000000) return (value / 1000000000).toFixed(2) + 'B';
    if (value >= 1000000) return (value / 1000000).toFixed(2) + 'M';
    if (value >= 1000) return (value / 1000).toFixed(2) + 'K';
    return value.toFixed(4);
  };

  const options = {
    chart: {
      height: '100%',
      type: 'line',
      zoom: { enabled: false },
      toolbar: { show: false }
    },
    dataLabels: { enabled: false },
    stroke: { width: [4, 4], curve: 'smooth' },
    legend: { position: 'top' },
    xaxis: { categories: data.map(item => item.date) },
    yaxis: [
      {
        title: { text: 'MFT Preis ($)' },
        labels: {
          formatter: function (value) {
            return '$' + value.toFixed(4);
          }
        },
        min: 0,
        max: Math.max(...data.map(item => item.price)) * 1.1
      },
      {
        opposite: true,
        title: { text: 'Gesamtverkäufe (MFT)' },
        labels: {
          formatter: function (value) {
            return formatCurrency(value);
          }
        },
        min: 0,
        max: Math.max(...data.map(item => item.sold)) * 1.1
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return "$" + y.toFixed(4);
            }
            return y;
          }
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return formatCurrency(y) + " MFT";
            }
            return y;
          }
        }
      ]
    },
    colors: ['#1E90FF', '#32CD32']
  };

  const series = [
    {
      name: "MFT Preis",
      data: data.map(item => item.price)
    },
    {
      name: 'Gesamtverkäufe',
      data: data.map(item => item.sold)
    }
  ];

  return (
    <div style={{ 
      margin: '50px auto', 
      maxWidth: '100%', 
      padding: '20px',
      textAlign: 'center',
    }}>
      <h2 style={{ 
        color: 'black', 
        marginBottom: '20px',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '24px' // Mobilde daha küçük bir başlık
      }}>
        MFT-Wachstumsziel
      </h2>

      <div style={{ padding: '0 10px' }}> {/* Mobilde padding daha dar */}
        <ReactApexChart options={options} series={series} type="line" height={300} /> {/* Yüksekliği azaltıldı */}
      </div>
    </div>
  );
};

export default MFTStory;