import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 40px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
`;

const Title = styled.h2`
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: 600;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: #F7941D;
    border-radius: 2px;
  }
`;

const LevelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin: 0 auto 30px;
  padding: 0 15px;
`;

const LevelCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px;
  width: 220px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: #F7941D;
    border-radius: 2px;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(247, 148, 29, 0.1);
  }
`;

const LevelTitle = styled.h3`
  color: #1a1a1a;
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-weight: 600;
`;

const Percentage = styled.div`
  font-size: 2.5rem;
  color: #F7941D;
  font-weight: 700;
  margin: 10px 0;
  position: relative;
  
  &:after {
    content: 'Provision';
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem;
    color: #666;
    width: 100%;
  }
`;

const LevelDescription = styled.p`
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 15px;
`;

const BonusSection = styled.div`
  background: white;
  padding: 30px;
  border-radius: 20px;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
`;

const BonusTitle = styled.h3`
  color: #1a1a1a;
  margin-bottom: 25px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background: #F7941D;
    border-radius: 2px;
  }
`;

const BonusList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 15px;
`;

const BonusItem = styled.div`
  background: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid transparent;

  &:hover {
    border-color: #F7941D;
    transform: translateY(-2px);
  }

  h4 {
    color: #F7941D;
    font-size: 1.1rem;
    margin-bottom: 10px;
    font-weight: 600;
  }

  p {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.4;
  }
`;

const TestButton = styled.button`
  background: #F7941D;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  margin: 30px auto;
  display: block;
  transition: all 0.3s ease;

  &:hover {
    background: #e88710;
    transform: translateY(-2px);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  padding: 20px;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 15px;
  width: 100%;
  max-width: 100%;
  height: 90vh;
  margin: 20px auto;
  position: relative;
  overflow-y: auto;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #1a1a1a;
    font-size: 1.8rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  
  &:hover {
    color: #F7941D;
  }
`;

const TestContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 20px;
  height: calc(100% - 150px);
`;

const NetworkTree = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  height: 100%;
  overflow-y: auto;

  .tree-container {
    min-width: auto;
    max-width: 100%;
  }
`;

const Summary = styled.div`
  background: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  height: 100%;
  overflow-y: auto;

  .summary-item {
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    background: #f8f9fa;
    transition: all 0.2s ease;

    &:hover {
      transform: translateX(5px);
      background: #f0f0f0;
    }

    .label {
      color: #666;
      font-size: 0.9rem;
      margin-bottom: 8px;
    }

    .value {
      color: #1a1a1a;
      font-size: 1.2rem;
      font-weight: 600;

      &.highlight {
        color: #F7941D;
      }

      &.commission {
        color: #28a745;
      }
    }
  }
`;

const AddPersonForm = styled.form`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  gap: 15px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AmountGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
`;

const AmountDisplay = styled.div`
  padding: 8px;
  background: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #666;
  font-size: 0.9rem;
  min-width: 120px;
  text-align: right;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9rem;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #F7941D;
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 0.9rem;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #F7941D;
  }
`;

const AddButton = styled.button`
  background: #F7941D;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.95rem;
  height: 42px;
  transition: all 0.2s ease;
  align-self: flex-end;
  margin-top: 22px;

  &:hover {
    background: #e88710;
    transform: translateY(-1px);
  }
`;

const PersonNode = styled.div`
  background: white;
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);

  .name {
    font-weight: 600;
    color: #1a1a1a;
  }

  .amount {
    color: #F7941D;
    margin-top: 5px;
  }

  .earnings {
    color: #28a745;
    margin-top: 5px;
  }
`;

const LevelSettings = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);

  h4 {
    margin-bottom: 20px;
    color: #1a1a1a;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-level-btn {
      background: #F7941D;
      color: white;
      border: none;
      padding: 8px 15px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 500;
      transition: all 0.2s ease;

      &:hover {
        background: #e88710;
        transform: translateY(-1px);
      }
    }
  }
`;

const LevelSettingRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const LevelSettingInput = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  background: white;
  border: 1px solid #eee;
  border-radius: 6px;
  width: 280px;
  justify-content: space-between;

  label {
    font-size: 0.9rem;
    color: #333;
    font-weight: 500;
    width: 55px;
  }

  .percentage-group {
    display: flex;
    align-items: center;
    margin-right: 15px;

    input {
      width: 45px;
      padding: 4px 6px;
      border: 1px solid #ddd;
      border-radius: 4px;
      text-align: right;
      font-size: 0.9rem;
      font-weight: 500;
      background: #f8f9fa;

      &:focus {
        outline: none;
        border-color: #F7941D;
        background: white;
      }
    }

    span {
      color: #666;
      margin-left: 4px;
      font-size: 0.9rem;
    }
  }

  .max-group {
    display: flex;
    align-items: center;

    span {
      color: #666;
      margin-right: 6px;
      font-size: 0.9rem;
    }

    input {
      width: 45px;
      padding: 4px 6px;
      border: 1px solid #ddd;
      border-radius: 4px;
      text-align: right;
      font-size: 0.9rem;
      font-weight: 500;
      background: #f8f9fa;

      &:focus {
        outline: none;
        border-color: #F7941D;
        background: white;
      }
    }
  }

  .remove-level-btn {
    margin-left: 10px;
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1.1rem;
    padding: 0 4px;
    opacity: 0.7;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 1;
    }
  }
`;

const CompactPersonNode = styled(PersonNode)`
  padding: 6px 10px;
  margin: 3px 0;
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 0.5fr 40px;
  gap: 8px;
  align-items: center;
  background: white;
  transition: all 0.2s ease;
  font-size: 0.85rem;

  .delete-btn {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1.1rem;
    padding: 4px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:hover {
      opacity: 1;
      background: rgba(220, 53, 69, 0.1);
    }
  }

  &:hover {
    transform: translateX(3px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .name {
    font-weight: 500;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 0.85rem;
  }

  .amount {
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 2px;

    small {
      color: #666;
      font-size: 0.7rem;
    }
  }

  .earnings {
    font-size: 0.8rem;
    text-align: right;
  }

  .downline {
    text-align: center;
    background: #f8f9fa;
    padding: 3px;
    border-radius: 3px;
    font-size: 0.7rem;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  background: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;

  &:before {
    content: "⚠️";
  }
`;

const AlertModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  min-width: 300px;
  text-align: center;

  .alert-title {
    color: #dc3545;
    font-size: 1.1rem;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    &:before {
      content: "⚠️";
    }
  }

  .alert-actions {
    margin-top: 20px;

    button {
      background: #dc3545;
      color: white;
      border: none;
      padding: 8px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        background: #c82333;
      }
    }
  }
`;

const InfoIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #F7941D;
  color: white;
  font-size: 12px;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease;

  &:hover {
    background: #e88710;
    transform: scale(1.1);
  }
`;

const InfoTooltip = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  width: 250px;
  font-size: 0.85rem;
  color: #666;
  z-index: 1000;
  line-height: 1.4;
  display: ${props => props.show ? 'block' : 'none'};
`;

const FormLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
`;

const SectionTitle = styled.div`
  color: #1a1a1a;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
`;

const LevelBadge = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  background: #F7941D;
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.7rem;
  font-weight: 500;

  .level-number {
    font-weight: 600;
  }

  .percentage {
    opacity: 0.9;
  }
`;

const NetworkMarketingSystem = () => {
  const [showTestModal, setShowTestModal] = useState(false);
  const [network, setNetwork] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    amount: '',
    parentId: ''
  });
  const [levels, setLevels] = useState([
    { percentage: 5, maxDownline: 5 },
    { percentage: 3, maxDownline: 10 },
    { percentage: 2, maxDownline: 15 },
    { percentage: 1.5, maxDownline: 20 },
    { percentage: 1, maxDownline: 25 }
  ]);
  const [formattedAmount, setFormattedAmount] = useState('');
  const [error, setError] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [tooltips, setTooltips] = useState({});

  const MFT_PRICE = 0.0050; // USD

  const handleLevelChange = (index, value) => {
    const newLevels = [...levels];
    newLevels[index].percentage = parseFloat(value) || 0;
    setLevels(newLevels);
    // Reset network when percentages change
    setNetwork([]);
  };

  const getPersonLevel = (personId) => {
    let level = 0;
    let currentId = personId;
    
    while (true) {
      const person = network.find(p => p.id === currentId);
      if (!person || !person.parentId) break;
      level++;
      currentId = person.parentId;
    }
    
    return level;
  };

  const canAddDownline = (parentId) => {
    if (!parentId) return true;
    
    const parent = network.find(p => p.id === parentId);
    if (!parent) return false;
    
    const parentLevel = getPersonLevel(parentId);
    const directDownlines = network.filter(p => p.parentId === parentId).length;
    
    return directDownlines < levels[parentLevel]?.maxDownline || false;
  };

  const calculateEarnings = (person) => {
    let totalEarnings = 0;
    const personLevel = getPersonLevel(person.id);

    // Kişi son seviyedeyse kazanç hesaplanmaz
    if (personLevel >= levels.length - 1) return 0;

    const findChildren = (parentId, currentLevel = 0) => {
      if (currentLevel >= levels.length - 1) return 0;
      
      const children = network.filter(p => p.parentId === parentId);
      let levelEarnings = 0;

      children.forEach(child => {
        const childAmount = parseFloat(child.amount);
        levelEarnings += childAmount * MFT_PRICE * (levels[currentLevel].percentage / 100);
        levelEarnings += findChildren(child.id, currentLevel + 1);
      });

      return levelEarnings;
    };

    totalEarnings = findChildren(person.id);
    return totalEarnings;
  };

  const handleMaxDownlineChange = (index, value) => {
    const newValue = parseInt(value) || 0;
    if (newValue < 0) return;
    
    const newLevels = [...levels];
    newLevels[index].maxDownline = newValue;
    setLevels(newLevels);
  };

  const getAvailableParents = () => {
    return network;
  };

  const validateAddPerson = (parentId, amount) => {
    // Ana seviye kontrolü
    if (!parentId) {
      if (network.filter(p => !p.parentId).length >= 100) {
        throw new Error('Ana seviyede maksimum 100 kişi olabilir!');
      }
      return true;
    }

    const parent = network.find(p => p.id === parentId);
    if (!parent) {
      throw new Error('Seçilen üst kişi bulunamadı!');
    }

    const parentLevel = getPersonLevel(parentId);
    
    // Alt seviye sayısı kontrolü
    const directDownlines = network.filter(p => p.parentId === parentId).length;
    if (directDownlines >= levels[parentLevel].maxDownline) {
      throw new Error(`Level ${parentLevel + 1} için maksimum alt seviye sayısına ulaşıldı! (Max: ${levels[parentLevel].maxDownline})`);
    }

    // Miktar kontrolü
    if (amount <= 0) {
      throw new Error('MFT miktarı 0\'dan büyük olmalıdır!');
    }

    return true;
  };

  const handleAddPerson = (e) => {
    e.preventDefault();
    
    try {
      validateAddPerson(formData.parentId, parseFloat(formData.amount));

      const newPerson = {
        id: Date.now().toString(),
        name: formData.name,
        amount: parseFloat(formData.amount),
        parentId: formData.parentId || null
      };

      setNetwork([...network, newPerson]);
      setFormData({ name: '', amount: '', parentId: '' });
    } catch (err) {
      setAlertMessage(err.message);
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleDeletePerson = (personId) => {
    // Kişinin alt ağını bul
    const findAllChildren = (id) => {
      const children = network.filter(p => p.parentId === id);
      let allChildren = [...children];
      
      children.forEach(child => {
        allChildren = [...allChildren, ...findAllChildren(child.id)];
      });
      
      return allChildren;
    };

    const childrenToDelete = findAllChildren(personId);
    const allIdsToDelete = [personId, ...childrenToDelete.map(c => c.id)];
    
    // Silme işlemini onayla
    if (childrenToDelete.length > 0) {
      if (!window.confirm(`Bu kişiyi ve altındaki ${childrenToDelete.length} kişiyi silmek istediğinize emin misiniz?`)) {
        return;
      }
    } else {
      if (!window.confirm('Bu kişiyi silmek istediğinize emin misiniz?')) {
        return;
      }
    }
    
    // Ağdan kişileri kaldır
    setNetwork(network.filter(person => !allIdsToDelete.includes(person.id)));
  };

  const renderNetwork = (parentId = null, level = 0) => {
    const people = network.filter(p => p.parentId === parentId);
    
    return (
      <div className="tree-container">
        {people.map(person => {
          const personLevel = getPersonLevel(person.id);
          const levelData = levels[personLevel] || { percentage: 0, maxDownline: 0 };
          
          return (
            <div key={person.id} style={{ marginLeft: level * 15 + 'px' }}>
              <CompactPersonNode>
                <div className="name">
                  {person.name}
                  <LevelBadge>
                    <span className="level-number">L{personLevel + 1}</span>
                    <span className="percentage">{levelData.percentage}%</span>
                  </LevelBadge>
                </div>
                <div className="amount">
                  {formatNumber(person.amount)} MFT
                  <small>${formatNumber(person.amount * MFT_PRICE)}</small>
                </div>
                <div className="earnings">
                  ${formatNumber(calculateEarnings(person))}
                </div>
                <div className="downline">
                  {network.filter(p => p.parentId === person.id).length}/{levelData.maxDownline}
                </div>
                <button 
                  className="delete-btn" 
                  onClick={() => handleDeletePerson(person.id)}
                  title="Kişiyi Sil"
                >
                  ×
                </button>
              </CompactPersonNode>
              {renderNetwork(person.id, level + 1)}
            </div>
          );
        })}
      </div>
    );
  };

  const calculateTotalPayouts = () => {
    let total = 0;
    network.forEach(person => {
      total += calculateEarnings(person);
    });
    return total;
  };

  const handleAmountChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    if (value) {
      value = parseInt(value).toLocaleString('de-DE');
    }
    setFormData({...formData, amount: value.replace(/\./g, '')});
  };

  const addNewLevel = () => {
    if (levels.length >= 10) {
      alert('Maksimum 10 level eklenebilir!');
      return;
    }
    
    const lastLevel = levels[levels.length - 1];
    const newLevel = {
      percentage: Math.max(0.5, lastLevel.percentage - 0.5),
      maxDownline: Math.min(100, lastLevel.maxDownline + 5)
    };
    
    const newLevels = [...levels, newLevel];
    setLevels(newLevels);
  };

  const removeLevel = (index) => {
    if (network.some(person => getPersonLevel(person.id) === index)) {
      alert('Bu seviyede kişiler var, seviye silinemez!');
      return;
    }
    
    const newLevels = levels.filter((_, i) => i !== index);
    setLevels(newLevels);
  };

  const toggleTooltip = (id) => {
    setTooltips(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <Container>
      <div className="container">
        <Title>MyFutureToken (MFT) Network Marketing System</Title>
        
        <LevelContainer>
          <LevelCard>
            <LevelTitle>Level 1: Direkte Empfehlung</LevelTitle>
            <Percentage>5%</Percentage>
            <LevelDescription>
              Verdienen Sie 5% Provision von allen direkten Empfehlungen. 
              Ihre persönlich geworbenen Teammitglieder sind Ihre erste Einnahmequelle.
            </LevelDescription>
          </LevelCard>

          <LevelCard>
            <LevelTitle>Level 2: Team-Expansion</LevelTitle>
            <Percentage>3%</Percentage>
            <LevelDescription>
              Erhalten Sie 3% von allen Käufen der zweiten Ebene. 
              Wenn Ihre direkten Empfehlungen neue Mitglieder werben, profitieren Sie mit.
            </LevelDescription>
          </LevelCard>

          <LevelCard>
            <LevelTitle>Level 3: Netzwerk-Wachstum</LevelTitle>
            <Percentage>2%</Percentage>
            <LevelDescription>
              Sichern Sie sich 2% von der dritten Ebene Ihres Netzwerks. 
              Ihr passives Einkommen wächst mit jedem neuen Teammitglied.
            </LevelDescription>
          </LevelCard>

          <LevelCard>
            <LevelTitle>Level 4: Community-Building</LevelTitle>
            <Percentage>1.5%</Percentage>
            <LevelDescription>
              Profitieren Sie mit 1,5% von der vierten Ebene. 
              Bauen Sie eine starke Community auf und maximieren Sie Ihre Einnahmen.
            </LevelDescription>
          </LevelCard>

          <LevelCard>
            <LevelTitle>Level 5: Leadership</LevelTitle>
            <Percentage>1%</Percentage>
            <LevelDescription>
              Genießen Sie 1% passive Einnahmen von der fünften Ebene. 
              Als Leader profitieren Sie von einem weitverzweigten Netzwerk.
            </LevelDescription>
          </LevelCard>
        </LevelContainer>

        <BonusSection>
          <BonusTitle>Exklusive Bonus-Programme</BonusTitle>
          <BonusList>
            <BonusItem>
              <h4>Pool Bonus</h4>
              <p>10% aller Systemeinnahmen werden monatlich an Top-Performer verteilt</p>
            </BonusItem>
            <BonusItem>
              <h4>Leadership Bonus</h4>
              <p>Zusätzliche Belohnungen für das Erreichen von Meilensteinen</p>
            </BonusItem>
            <BonusItem>
              <h4>Team Challenge</h4>
              <p>Vierteljährliche Teamwettbewerbe mit Sonderprämien</p>
            </BonusItem>
            <BonusItem>
              <h4>Early Adopter Bonus</h4>
              <p>Spezielle Boni für frühe Netzwerk-Mitglieder</p>
            </BonusItem>
          </BonusList>
        </BonusSection>

        <TestButton onClick={() => setShowTestModal(true)}>
          Network Marketing Test Simulator
        </TestButton>

        {showTestModal && (
          <ModalOverlay>
            <ModalContent>
              <CloseButton onClick={() => setShowTestModal(false)}>&times;</CloseButton>
              <h2>Network Marketing Test Simulator</h2>
              
              <LevelSettings>
                <SectionTitle>
                  Network Marketing Seviye Ayarları
                  <InfoIcon onClick={() => toggleTooltip('levels')}>i</InfoIcon>
                  <InfoTooltip show={tooltips.levels}>
                    Her seviye için komisyon yüzdesini ve maksimum alt üye sayısını ayarlayabilirsiniz. 
                    Yeni seviyeler ekleyebilir veya mevcut seviyeleri düzenleyebilirsiniz.
                  </InfoTooltip>
                </SectionTitle>
                <h4>
                  <button className="add-level-btn" onClick={addNewLevel}>
                    + Yeni Seviye Ekle
                  </button>
                </h4>
                <LevelSettingRow>
                  {levels.map((level, index) => (
                    <LevelSettingInput key={index}>
                      <label>Level {index + 1}</label>
                      <div className="percentage-group">
                        <input
                          type="number"
                          step="0.1"
                          min="0"
                          max="100"
                          value={level.percentage}
                          onChange={(e) => handleLevelChange(index, e.target.value)}
                        />
                        <span>%</span>
                      </div>
                      <div className="max-group">
                        <span>Max:</span>
                        <input
                          type="number"
                          value={level.maxDownline}
                          onChange={(e) => handleMaxDownlineChange(index, e.target.value)}
                          min="1"
                        />
                      </div>
                      {index > 4 && (
                        <button
                          className="remove-level-btn"
                          onClick={() => removeLevel(index)}
                          title="Seviyeyi Sil"
                        >
                          ×
                        </button>
                      )}
                    </LevelSettingInput>
                  ))}
                </LevelSettingRow>
              </LevelSettings>

              <TestContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <NetworkTree>
                  <AddPersonForm onSubmit={handleAddPerson}>
                    <FormGroup>
                      <FormLabel>
                        İsim:
                        <InfoIcon onClick={() => toggleTooltip('name')}>i</InfoIcon>
                        <InfoTooltip show={tooltips.name}>
                          Ağa eklemek istediğiniz kişinin adını girin. Bu isim, ağ yapısında görüntülenecek ve 
                          referans olarak kullanılacaktır.
                        </InfoTooltip>
                      </FormLabel>
                      <Input
                        type="text"
                        value={formData.name}
                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                        required
                        placeholder="Kişi adını girin"
                      />
                    </FormGroup>

                    <FormGroup>
                      <FormLabel>
                        MFT Miktarı:
                        <InfoIcon onClick={() => toggleTooltip('amount')}>i</InfoIcon>
                        <InfoTooltip show={tooltips.amount}>
                          Kişinin satın aldığı MFT token miktarını girin. Bu miktar üzerinden komisyon 
                          hesaplamaları yapılacaktır. Minimum 1 MFT gereklidir.
                        </InfoTooltip>
                      </FormLabel>
                      <AmountGroup>
                        <Input
                          type="text"
                          value={formData.amount ? parseInt(formData.amount).toLocaleString('de-DE') : ''}
                          onChange={handleAmountChange}
                          placeholder="Token miktarını girin"
                          required
                        />
                        <AmountDisplay>
                          ${formData.amount ? (parseFloat(formData.amount) * MFT_PRICE).toFixed(2) : '0.00'}
                        </AmountDisplay>
                      </AmountGroup>
                    </FormGroup>

                    <FormGroup>
                      <FormLabel>
                        Üst Kişi:
                        <InfoIcon onClick={() => toggleTooltip('parent')}>i</InfoIcon>
                        <InfoTooltip show={tooltips.parent}>
                          Eklenecek kişinin bağlı olacağı üst kişiyi seçin. Ana seviye seçeneği, 
                          kişiyi direkt olarak ağın en üst seviyesine ekler.
                        </InfoTooltip>
                      </FormLabel>
                      <Select
                        value={formData.parentId}
                        onChange={(e) => setFormData({...formData, parentId: e.target.value})}
                      >
                        <option value="">Ana Seviye</option>
                        {getAvailableParents().map(person => {
                          const level = getPersonLevel(person.id);
                          return (
                            <option key={person.id} value={person.id}>
                              {person.name} (Level {level + 1})
                            </option>
                          );
                        })}
                      </Select>
                    </FormGroup>
                    <AddButton type="submit">Ekle</AddButton>
                  </AddPersonForm>

                  {renderNetwork()}
                </NetworkTree>

                <Summary>
                  <SectionTitle>
                    Ağ Özeti
                    <InfoIcon onClick={() => toggleTooltip('summary')}>i</InfoIcon>
                    <InfoTooltip show={tooltips.summary}>
                      Bu bölümde ağınızın genel durumunu görebilirsiniz. Toplam kişi sayısı, 
                      toplam MFT satışı, satış değeri ve ödenecek komisyonlar burada listelenir.
                    </InfoTooltip>
                  </SectionTitle>
                  <div className="summary-item">
                    <div className="label">Toplam Kişi</div>
                    <div className="value">{network.length}</div>
                  </div>
                  <div className="summary-item">
                    <div className="label">Toplam MFT Satışı</div>
                    <div className="value highlight">{formatNumber(network.reduce((sum, p) => sum + p.amount, 0))} MFT</div>
                  </div>
                  <div className="summary-item">
                    <div className="label">Toplam Satış Değeri</div>
                    <div className="value highlight">${formatNumber(network.reduce((sum, p) => sum + p.amount, 0) * MFT_PRICE)}</div>
                  </div>
                  <div className="summary-item">
                    <div className="label">Toplam Ödenecek Komisyon</div>
                    <div className="value commission">${formatNumber(calculateTotalPayouts())}</div>
                  </div>
                </Summary>
              </TestContainer>
            </ModalContent>
          </ModalOverlay>
        )}

        {alertMessage && (
          <AlertModal>
            <div className="alert-title">{alertMessage}</div>
            <div className="alert-actions">
              <button onClick={() => setAlertMessage('')}>Tamam</button>
            </div>
          </AlertModal>
        )}
      </div>
    </Container>
  );
};

export default NetworkMarketingSystem; 