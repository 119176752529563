import React, { useEffect, useState } from 'react';

const styles = `
  .sales-bar-wrapper {
    padding: 60px 20px; /* Mobil uyumlu padding */
  }
  .sales-bar {
    padding: 20px; /* Mobil uyumlu padding */
    text-align: center;
    background-color: #ffffff;
    font-family: Arial, sans-serif;
  }

  .sales-bar h1 {
    font-size: 24px; /* Mobil uyumlu font boyutu */
    color: #333;
    margin-bottom: 10px; /* Mobilde daha az boşluk */
  }

  .sales-bar p {
    font-size: 16px; /* Açıklama metni için font boyutu */
    color: #555;
    margin-bottom: 20px;
  }

  .underline {
    width: 150px; /* Mobil uyumlu genişlik */
    height: 4px; /* Mobil uyumlu yükseklik */
    background-color: #ffa500;
    margin: 0 auto 20px; /* Mobilde daha az boşluk */
  }

  .progress-container {
    width: 80%; /* Mobilde genişliği artırmak */
    background-color: #ffffff;
    border-radius: 25px;
    margin: 0 auto;
    height: 20px; /* Mobil uyumlu yükseklik */
    position: relative;
    overflow: visible;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 2px solid #ffa500;
  }

  .progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #ffa500, #ff8c00);
    border-radius: 23px;
    transition: width 1s ease-in-out;
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .progress-popup {
    position: absolute;
    bottom: -50px; /* Mobilde daha yakın */
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #000000;
    padding: 8px 12px; /* Mobil uyumlu padding */
    border-radius: 15px;
    font-size: 12px; /* Mobil uyumlu font boyutu */
    font-weight: bold;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.5s ease-out, bottom 0.5s ease-out;
    border: 2px solid #ffa500;
    box-shadow: inset 0 0 3px rgba(255, 165, 0, 0.5);
  }

  .progress-popup::before {
    content: '';
    position: absolute;
    top: -8px; /* Mobil uyumlu ok boyutu */
    left: 50%;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffa500;
    transform: translateX(-50%);
  }

  .progress-popup.show {
    opacity: 1;
    bottom: -60px; /* Mobil uyumlu pozisyon */
  }
`;

const ProgressBar = ({ soldAmount, totalAmount = 10000000000 }) => {
  const [progress, setProgress] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const progressPercentage = (soldAmount / totalAmount) * 100;
    
    const timer = setTimeout(() => {
      setProgress(progressPercentage);
      setShowPopup(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, [soldAmount, totalAmount]);

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%` }} />
      <div 
        className={`progress-popup ${showPopup ? 'show' : ''}`} 
        style={{ left: `${progress}%` }}
      >
        {soldAmount.toLocaleString()} MFT verkauft
      </div>
    </div>
  );
};

const SalesBar = () => {
  const [soldAmount, setSoldAmount] = useState(1000000000);

  useEffect(() => {
    // API çağrısı simülasyonu
    const fetchSoldAmount = () => {
      setSoldAmount(16000000); // Gerçek satış miktarı ile güncelleyin
    };

    fetchSoldAmount();
  }, []);

  return (
    <div className="sales-bar-wrapper">
      <style>{styles}</style>
      <div className="sales-bar">
        <h1>MyFutureToken Verkaufsfortschritt</h1>
        <p>Bis jetzt verkaufte MFT-Menge.</p> {/* Almanca açıklama eklendi */}
        <div className="underline"></div>
        <ProgressBar soldAmount={soldAmount} />
      </div>
    </div>
  );
};

export default SalesBar;